<template>
  <ClientOnly>
    <div :class="isRolling ? 'animation-number-cover-rolling' : 'animation-number-cover'">
      <CountUp
        :key="initNumber"
        ref="countUpRef"
        class="animation-number"
        :end-val="endValue"
        :start-val="startValue"
        :duration="duration"
        :autoplay="true"
      />
      <template>
        <BaseImg v-if="showCoin" src="/assets/images/icon/coin-k.svg" class="icon-coin" alt="icon-coin" />
        <span v-else class="animation-number-text">Đ</span>
      </template>
    </div>
  </ClientOnly>
</template>
<script setup>
import { ref, watch, computed } from 'vue'
import CountUp from './count-up.vue'
const { $device, $config } = useNuxtApp()
const props = defineProps({
  number: {
    type: [Number, String],
    default: 0
  },
  initNumber: {
    type: Number,
    default: 0
  },
  showCoin: {
    type: Boolean,
    show: false
  },
  isRolling: {
    type: Boolean,
    default: true
  }
})
const countUpRef = ref(null)
const startValue = ref(props.initNumber || props.number)
const animationRaise = startValue.value * 0.1
const endValue = ref(Number(props.number) + animationRaise)
const duration = ref((animationRaise * 10) / Number($config.public.JACKPOT_ANIMATION_SPEED))
watch(
  () => props.initNumber,
  (newNumber) => {
    if (countUpRef.value) {
      startValue.value = newNumber || props.number
      endValue.value = props.number
      countUpRef.value.restart()
    }
  }
)
const width = computed(() => {
  return String(props.number).length * 12.5
})
</script>
<style lang="scss" scoped src="assets/scss/components/common/animate-count-up.scss"></style>
