<script>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { CountUp } from 'countup.js'
// Helper function for RAF delay
function useRaf(cb, delaySeconds = 1) {
  const rafId = ref(-1)
  let startTime
  function count(timestamp) {
    if (!startTime) {
      startTime = timestamp
    }
    const diff = timestamp - startTime
    if (diff < delaySeconds * 1000) {
      rafId.value = requestAnimationFrame(count)
    } else {
      cb()
    }
  }
  rafId.value = requestAnimationFrame(count)
  function cancel() {
    window.cancelAnimationFrame(rafId.value)
  }
  return { cancel }
}
export default {
  name: 'CountUp',
  props: {
    endVal: {
      type: [Number, String],
      required: true
    },
    startVal: {
      type: [Number, String],
      default: 0
    },
    duration: {
      type: [Number, String],
      default: 2.5
    },
    decimalPlaces: {
      type: Number,
      default: 0
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    loop: {
      type: [Boolean, Number],
      default: false
    },
    delay: {
      type: Number,
      default: 0
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['init', 'finished'],
  setup(props, { emit, expose }) {
    const elRef = ref(null)
    const countUp = ref(null)
    const finished = ref(false)
    let loopCount = 0
    let rafCtx = null
    function initCountUp() {
      if (!elRef.value) {
        console.warn('[vue-countup]', 'Element reference not found')
        return
      }
      loopCount = 0
      finished.value = false
      const startVal = Number(props.startVal)
      const endVal = Number(props.endVal)
      const duration = Number(props.duration)
      countUp.value = new CountUp(elRef.value, endVal, {
        startVal,
        duration,
        decimalPlaces: props.decimalPlaces,
        ...props.options
      })
      if (countUp.value.error) {
        console.error('[vue-countup]', countUp.value.error)
        return
      }
      emit('init', countUp.value)
    }
    function startAnimation() {
      if (!countUp.value) {
        initCountUp()
      }
      countUp.value?.start(_loop)
      loopCount++
      function _loop() {
        const isTruely = typeof props.loop === 'boolean' && props.loop
        if (isTruely || props.loop > loopCount) {
          rafCtx = useRaf(() => {
            countUp.value?.reset()
            startAnimation()
          }, props.delay)
        } else {
          finished.value = true
        }
      }
    }
    function restart() {
      rafCtx?.cancel()
      initCountUp()
      startAnimation()
    }
    // Expose additional CountUp.js methods
    function update(newEndVal) {
      countUp.value?.update(newEndVal)
    }
    function pauseResume() {
      countUp.value?.pauseResume()
    }
    function reset() {
      countUp.value?.reset()
    }
    // Watch for changes in start and end values
    watch([() => props.startVal, () => props.endVal], () => {
      if (props.autoplay) {
        restart()
      }
    })
    // Watch for animation completion
    watch(finished, (flag) => {
      if (flag) {
        if (props.options?.onCompleteCallback) {
          props.options.onCompleteCallback()
        }
        emit('finished')
      }
    })
    onMounted(() => {
      initCountUp()
      if (props.autoplay) {
        startAnimation()
      }
    })
    onUnmounted(() => {
      rafCtx?.cancel()
      countUp.value?.reset()
    })
    // Expose methods for external use
    expose({
      init: initCountUp,
      restart,
      update,
      pauseResume,
      reset,
      countUp
    })
    return {
      elRef
    }
  }
}
</script>
<template>
  <div class="countup-wrap">
    <slot name="prefix" />
    <span ref="elRef" />
    <slot name="suffix" />
  </div>
</template>
